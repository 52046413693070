<template>
  <mdb-navbar color="" class="main-top-bar">
    <mdb-container>
      <mdb-row class="w-100 m-0">
        <mdb-col md="3">
          <mdb-navbar-brand :to="{ name: 'home' }">
            <img :src="imgPath + 'logo-iz.png'" class="logo" alt="" />
          </mdb-navbar-brand>
        </mdb-col>
        <mdb-col
          md="6"
          class="d-flex justify-content-center align-items-center "
        >
          <mdb-navbar-toggler>
            <mdb-navbar-nav class="mx-auto main-menu">
              <router-link :to="{ name: 'home' }">Accueil</router-link>

              <router-link :to="{ name: 'panier' }">Panier</router-link>
              <router-link :to="{ name: 'commande' }"
                >Mes commandes</router-link
              >
            </mdb-navbar-nav>
          </mdb-navbar-toggler>
        </mdb-col>

        <mdb-col
          md="3"
          class="d-flex  align-items-center text-right cnt-account-menu"
        >
          <mdb-navbar-nav left class="text-right mr-0">
            <mdb-dropdown
              btn-group
              dropleft
              style="margin-left: 200px"
              class="sns-after"
            >
              <mdb-dropdown-toggle slot="toggle">
                <mdb-icon icon="user-circle" class="fa-2x"></mdb-icon>
              </mdb-dropdown-toggle>
              <mdb-dropdown-menu v-if="!userLoggenIn">
                <mdb-dropdown-item :to="{ name: 'login' }"
                  >Se connecter</mdb-dropdown-item
                >
                <div class="dropdown-divider"></div>
                <mdb-dropdown-item :to="{ name: 'register' }"
                  >S'inscrire</mdb-dropdown-item
                >
              </mdb-dropdown-menu>
              <mdb-dropdown-menu v-else>
                <mdb-dropdown-item :to="{ name: 'account' }"
                  >Mon compte</mdb-dropdown-item
                >
                <div class="dropdown-divider"></div>
                <mdb-dropdown-item @click="logout"
                  >Se doconnecter</mdb-dropdown-item
                >
              </mdb-dropdown-menu>
            </mdb-dropdown>
          </mdb-navbar-nav>
        </mdb-col>
      </mdb-row>
    </mdb-container>
  </mdb-navbar>
</template>

<script>
import {
  mdbContainer,
  mdbNavbar,
  mdbNavbarBrand,
  mdbNavbarToggler,
  mdbNavbarNav,
  mdbRow,
  mdbCol,
  mdbDropdown,
  mdbDropdownItem,
  mdbDropdownMenu,
  mdbDropdownToggle,
  mdbIcon,
} from "mdbvue";
import { mapGetters } from "vuex";
export default {
  name: "homeNavBar",
  components: {
    mdbContainer,
    mdbNavbar,
    mdbNavbarBrand,
    mdbNavbarToggler,
    mdbNavbarNav,
    mdbRow,
    mdbCol,
    mdbDropdown,
    mdbDropdownItem,
    mdbDropdownMenu,
    mdbDropdownToggle,
    mdbIcon,
  },
  data() {
    return {
      imgPath: "images/",
    };
  },
  computed: {
    ...mapGetters("auth", ["userLoggenIn"]),
  },
  methods: {
    logout() {
      localStorage.removeItem("user_access_token");
      location.reload();
    },
  },
};
</script>

<style scoped></style>
