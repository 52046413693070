<template>
  <div>
    <section class="top-mark-banier pt-4">
      <mdb-container>
        <mdb-col md="12" class="alg-container">
          <div class="cnt-top-crumbs">
            <mdb-breadcrumb class="main-crumbs">
              <mdb-breadcrumb-item
                ><router-link :to="{ name: 'home' }"
                  >Accueil</router-link
                ></mdb-breadcrumb-item
              >
              <mdb-breadcrumb-item
                ><router-link :to="{ name: 'categorielist' }"
                  >Pizza</router-link
                ></mdb-breadcrumb-item
              >
              <mdb-breadcrumb-item active>Chez bon pizza</mdb-breadcrumb-item>
            </mdb-breadcrumb>
          </div>

          <mdb-col md="12" class="banner-container p-0">
            <div
              class="top-banner-img w-100 d-flex"
              style="background: url('images/bread-delicious-egg-704569.png') no-repeat center;"
            >
              <div class="cnt-mark-logo-detail">
                <img
                  :src="imgPath + 'logos/bon-pizza.png'"
                  alt=""
                  class="mark-logo"
                />
              </div>
            </div>

            <!-- Modal -->

            <mdb-modal class="modal-about" :show="modal" @close="modal = false">
              <mdb-modal-header>
                <mdb-modal-title
                  >A propos <mdb-icon icon="heart"></mdb-icon
                ></mdb-modal-title>
              </mdb-modal-header>
              <mdb-modal-body>
                <mdb-row>
                  <mdb-col md="6" class="item-top-info text-left">
                    <h6 class="top-info-ft">
                      <span>Lieu de retrait: <br /></span>
                      Rue de Rivoli 75004
                    </h6>
                  </mdb-col>
                  <mdb-col md="6" class="item-top-info text-right">
                    <h6 class="top-info-ft">
                      <span>Jour de retrait: <br /></span>
                      Lundi, Jeudi
                    </h6>
                  </mdb-col>
                </mdb-row>

                <mdb-row>
                  <mdb-col md="12" class="cnt-rating-comments">
                    <div class="rating-container">
                      Avis:
                      <span class="rating"
                        ><mdb-icon icon="star" class="mr-2"></mdb-icon>
                        4.5</span
                      >
                    </div>
                  </mdb-col>
                </mdb-row>

                <mdb-row class="item-rating">
                  <mdb-col md="6">
                    <h6 class="comment-name m-0">
                      Anonyme
                    </h6>
                  </mdb-col>
                  <mdb-col md="6" class="cnt-rating-star text-right">
                    <mdb-rating v-model="value" />
                  </mdb-col>
                  <mdb-col md="12" class="comment-container">
                    Bonne pizza
                    <hr class="sep-comment" />
                  </mdb-col>
                </mdb-row>

                <mdb-row class="item-rating">
                  <mdb-col md="6">
                    <h6 class="comment-name m-0">
                      John Doe
                    </h6>
                  </mdb-col>
                  <mdb-col md="6" class="cnt-rating-star text-right">
                    <mdb-rating v-model="value2" />
                  </mdb-col>
                  <mdb-col md="12" class="comment-container">
                    Les pizza sont excellentes, mais nous avons du attendre ...
                    <hr class="sep-comment" />
                  </mdb-col>
                </mdb-row>

                <mdb-row>
                  <mdb-col md="12" class="form-rating-container">
                    <mdb-input
                      outline
                      type="textarea"
                      :rows="4"
                      label="Votre commentaire"
                    />
                  </mdb-col>
                </mdb-row>
                <mdb-row class="item-rating">
                  <mdb-col md="4" class="">
                    <h6 class="comment-name m-0">
                      Votre avis
                    </h6>
                  </mdb-col>
                  <mdb-col md="8" class="cnt-rating-star text-right">
                    <mdb-rating v-model="value3" />
                  </mdb-col>
                </mdb-row>
              </mdb-modal-body>
              <mdb-modal-footer class="text-center">
                <mdb-btn color="yellow" class="save-change">Poster</mdb-btn>
              </mdb-modal-footer>
            </mdb-modal>

            <!-- Modal item -->

            <mdb-modal
              class="modal-about"
              :show="modalInfo"
              @close="modalInfo = false"
            >
              <mdb-modal-header>
                <mdb-modal-title>A propos </mdb-modal-title>
              </mdb-modal-header>
              <mdb-modal-body>
                <mdb-row>
                  <mdb-col md="12" class="cnt-title-product">
                    <h6 class="title-product">
                      Pizza margerita 25cm
                    </h6>
                  </mdb-col>

                  <mdb-col md="12" class="item-top-info text-left">
                    <h6 class="top-info-ft">
                      <span>Lieu de retrait: <br /></span>
                      Rue de Rivoli 75004
                    </h6>
                  </mdb-col>
                  <mdb-col md="12" class="item-top-info text-right">
                    <h6 class="top-info-ft">
                      <span
                        >Temps restant avant la prochaine commande possible :<br
                      /></span>
                      4h
                    </h6>
                  </mdb-col>
                </mdb-row>

                <hr class="sep-comment my-3" />
                <mdb-row>
                  <mdb-col md="12" class="cnt-desc-product">
                    <p class="m-0">
                      Base creme fraiche, emmental, lardons auxchampignons et
                      fondue de poireaux, oignos, pomme de terre
                    </p>
                  </mdb-col>
                </mdb-row>
                <hr class="sep-comment my-3" />

                <mdb-row>
                  <mdb-col md="12" class="cnt-desc-product">
                    <p class="m-0">
                      - Contient du lait et des produits a base de lait (lactose
                      inclu)
                    </p>
                    <p class="m-0">
                      - Contient du dioxyde de souffre et des sulfites
                    </p>
                  </mdb-col>
                </mdb-row>
              </mdb-modal-body>
              <mdb-modal-footer class="text-center">
                <mdb-btn color="yellow" class="save-change"
                  >Ajouter au panier</mdb-btn
                >
              </mdb-modal-footer>
            </mdb-modal>

            <!-- Modal item -->

            <div class="about-mark-container w-100 pt-4">
              <div class="top-mark-name-container">
                <h5 class="mark-name">
                  Chez bon pizza
                  <div class="cnt-lnks-title">
                    <router-link :to="{ name: '' }" class="my-0">
                      <mdb-icon icon="share-alt" class=""></mdb-icon>
                    </router-link>
                    <mdb-btn
                      @click.native="modal = true"
                      class="mark-info my-0 p-0"
                      icon="exclamation-circle r-180"
                    ></mdb-btn>
                    <mdb-btn
                      class="mark-heart my-0 p-0 mr-0"
                      icon="heart"
                    ></mdb-btn>
                  </div>
                </h5>

                <p
                  class="rating-location-ft d-inline-flex align-items-center text-left w-auto"
                >
                  <span class="rating mr-2">
                    <mdb-icon icon="star" class="mr-1"></mdb-icon> 4.5
                  </span>
                  à 800 m - Food truck
                </p>

                <div class="cnt-about-ft">
                  <p>
                    Petit texte de présentation, petit texte de présentation,
                    petit texte de présentation, petit texte de présentation, ,
                    petit texte de présentation, petit texte de prése, petit
                    texte de présentation, petit texte de présentationntation
                    ...
                    <router-link :to="{ name: '' }" class="read-more">
                      Lire plus
                    </router-link>
                  </p>
                </div>
              </div>
            </div>
          </mdb-col>
        </mdb-col>
      </mdb-container>
    </section>

    <section class="menu-list-container">
      <mdb-container>
        <mdb-col md="12" class="py-4">
          <div class="top-menu-list-item ">
            <ul class="list-item pl-0 m-0">
              <li class="item-menu">
                <router-link class="active" :to="{ name: '' }">
                  Pizza junior
                </router-link>
              </li>
              <li class="item-menu">
                <router-link :to="{ name: '' }">
                  Pizza Maxi
                </router-link>
              </li>
            </ul>
          </div>

          <div class="cnt-good-mark w-100">
            <mdb-row class="pt-4">
              <mdb-col
                md="4"
                class="item-mark product"
                v-for="(mark, mr) in markrating"
                :key="mr"
              >
                <mdb-alert color="light" class="d-flex">
                  <div class="cnt-mark-logo">
                    <img :src="imgPath + 'plats/' + mark.img" alt="" />
                  </div>
                  <div class="cnt-about-mark">
                    <div class="top-mark-name">
                      <h6>
                        <router-link
                          :to="{
                            name: 'detailProduct',
                            params: { ID: mark.id, idProduct: mark.name },
                          }"
                        >
                          {{ mark.name }}
                        </router-link>
                      </h6>
                      <p class="desc">
                        {{ mark.desc }}
                      </p>
                    </div>
                    <div class="cnt-mark-location">
                      <p class="m-0 d-flex  align-items-center">
                        {{ mark.price }} €
                      </p>
                      <div class="cnt-lnks-title">
                        <mdb-btn
                          @click.native="modalInfo = true"
                          class="mark-info my-0 p-0"
                          icon="exclamation-circle r-180"
                        ></mdb-btn>
                        <mdb-btn
                          class="mark-heart my-0 p-0 mr-0"
                          icon="plus-circle"
                        ></mdb-btn>
                      </div>
                    </div>
                  </div>
                </mdb-alert>
              </mdb-col>
            </mdb-row>
          </div>
        </mdb-col>
      </mdb-container>
    </section>
  </div>
</template>

<script>
import {
  mdbCol,
  mdbContainer,
  mdbBreadcrumb,
  mdbBreadcrumbItem,
  mdbIcon,
  mdbBtn,
  mdbAlert,
  mdbRow,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
  mdbRating,
  mdbInput,
} from "mdbvue";
export default {
  name: "DetailCategory",
  components: {
    mdbCol,
    mdbContainer,
    mdbBreadcrumb,
    mdbBreadcrumbItem,
    mdbIcon,
    mdbBtn,
    mdbAlert,
    mdbRow,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbRating,
    mdbInput,
  },
  data() {
    return {
      imgPath: "images/",
      productName: "",
      markrating: [
        {
          name: "Pizza margerita 25cm",
          img: "bread-delicious-eggg-704569.png",
          id: "aze54138fsgfh",
          desc:
            "Base creme fraiche, emmental, lardons auxchampignons et fondue de poireaux, oignos, pomme de terre",
          price: "8.00",
        },
        {
          name: "Pizza margerita 25cm",
          img: "bread-delicious-egg-704569.png",
          id: "aze54138fsgfh",
          desc:
            "Base creme fraiche, emmental, lardons auxchampignons et fondue de poireaux, oignos, pomme de terre",
          price: "7.50",
        },
      ],
      modal: false,
      modalInfo: false,
      value: 5,
      value2: 4,
      value3: 0,
      isModalVisible: false,
    };
  },
  watch: {
    modal: function() {
      let el = document.body;
      if (this.modal === true) {
        el.classList.add("hidden-bar");
      } else {
        el.classList.remove("hidden-bar");
      }
    },
    modalInfo: function() {
      let el = document.body;
      if (this.modalInfo === true) {
        el.classList.add("hidden-bar");
      } else {
        el.classList.remove("hidden-bar");
      }
    },
  },
};
</script>
