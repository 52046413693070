<template>
  <div>
    <mdb-modal class="modal-about" :show="modalInfo" @close="modalInfo = false">
      <mdb-modal-header>
        <mdb-modal-title>A propos </mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col md="12" class="cnt-title-product">
            <h6 class="title-product">
              Pizza margerita 25cm
            </h6>
          </mdb-col>

          <mdb-col md="12" class="item-top-info text-left">
            <h6 class="top-info-ft">
              <span>Lieu de retrait: <br /></span>
              Rue de Rivoli 75004
            </h6>
          </mdb-col>
          <mdb-col md="12" class="item-top-info text-right">
            <h6 class="top-info-ft">
              <span
                >Temps restant avant la prochaine commande possible :<br
              /></span>
              4h
            </h6>
          </mdb-col>
        </mdb-row>

        <hr class="sep-comment my-3" />
        <mdb-row>
          <mdb-col md="12" class="cnt-desc-product">
            <p class="m-0">
              Base creme fraiche, emmental, lardons auxchampignons et fondue de
              poireaux, oignos, pomme de terre
            </p>
          </mdb-col>
        </mdb-row>
        <hr class="sep-comment my-3" />

        <mdb-row>
          <mdb-col md="12" class="cnt-desc-product">
            <p class="m-0">
              - Contient du lait et des produits a base de lait (lactose inclu)
            </p>
            <p class="m-0">
              - Contient du dioxyde de souffre et des sulfites
            </p>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer class="text-center">
        <mdb-btn color="yellow" class="save-change">Ajouter au panier</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
import {
  mdbCol,
  mdbBtn,
  mdbRow,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
} from "mdbvue";
export default {
  name: "modalinfo",
  components: {
    mdbCol,
    mdbBtn,
    mdbRow,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
  },
  props: {
    modalInfo: Boolean,
  },
  data() {
    return {
      value: 5,
      value2: 4,
      value3: 0,
    };
  },
};
</script>

<style scoped></style>
