import { render, staticRenderFns } from "./SingleProduct.vue?vue&type=template&id=887c0b06&scoped=true&"
import script from "./SingleProduct.vue?vue&type=script&lang=js&"
export * from "./SingleProduct.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "887c0b06",
  null
  
)

export default component.exports