<template>
  <div style="min-height: 50.2vh;">
    <section class="top-mark-banier pt-4">
      <mdb-container>
        <mdb-col md="12" class="alg-container">
          <div class="cnt-top-crumbs">
            <mdb-breadcrumb class="main-crumbs">
              <mdb-breadcrumb-item
                ><router-link :to="{ name: 'home' }"
                  >Accueil</router-link
                ></mdb-breadcrumb-item
              >
              <mdb-breadcrumb-item
                ><router-link :to="{ name: 'categorielist' }"
                  >Pizza</router-link
                ></mdb-breadcrumb-item
              >

              <mdb-breadcrumb-item>
                <router-link
                  :to="{
                    name: 'categoryDetail',
                    params: { ID: 'aze54138fsgfh' },
                  }"
                  >Chez bon pizza</router-link
                >
              </mdb-breadcrumb-item>
              <mdb-breadcrumb-item active>
                Pizza margerita 25cm</mdb-breadcrumb-item
              >
            </mdb-breadcrumb>
          </div>
        </mdb-col>
      </mdb-container>
    </section>
  </div>
</template>

<script>
import { mdbContainer, mdbCol, mdbBreadcrumb, mdbBreadcrumbItem } from "mdbvue";
export default {
  name: "SingleProduct",
  components: {
    mdbContainer,
    mdbCol,
    mdbBreadcrumb,
    mdbBreadcrumbItem,
  },
  data() {
    return {};
  },
};
</script>

<style scoped></style>
