const state = () => ({
    sidenav: true
})
const getters = {
    sidenav: state => state.sidenav
}
const mutations = {
    toggleSideNav: (state, value) => {
        if (typeof value === 'boolean') {
            state.sidenav = value;
            return;
        }
        state.sidenav = !state.sidenav;
    }
}
const actions = {
    toggleSideNav({ commit }, value) {
        commit('toggleSideNav', value)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}