import axios from "axios"

const state = () => ({
    categorys: null
})
const getters = {
    categorys: state => state.categorys
}

const actions = {
    categorys(context) {
        return new Promise((resolve, reject) => {
            axios.get('/category').then(response => {
                if (response.data) {
                    const data = response.data
                    context.commit('categorys', data)
                    resolve(response)
                }
            }).catch(err => {
                reject(err)
            })
        })
    }
}

const mutations = {
    categorys: (state, categorys) => {
        return state.categorys = categorys
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}