<template>
  <!-- Footer -->
  <mdb-footer color="black" class="font-small pt-4 mt-4">
    <mdb-container class="text-left">
      <mdb-col md="12">
        <mdb-row>
          <mdb-col md="6">
            <div class="alg-cnt-logo-footer">
              <div class="cnt-logo-footer">
                <img :src="imgPath + '/logo-footer.png'" alt="" />
              </div>
              <div class="cnt-logo-app">
                <img :src="imgPath + '/logos/google-play.png'" alt="" />
                <img :src="imgPath + '/logos/app-store.png'" alt="" />
              </div>
            </div>
          </mdb-col>
          <mdb-col md="3">
            <h5 class="title">Contactez - nous</h5>
            <ul class="pl-0">
              <li class="list-unstyled item-contact">
                <a to="#">
                  <mdb-icon icon="envelope" class="mr-2"></mdb-icon>
                  maztou@gmail.com
                </a>
              </li>
              <li class="list-unstyled item-contact">
                <a to="#">
                  <mdb-icon icon="phone-square-alt" class="mr-2"></mdb-icon>
                  +262 20 22 XX XX
                </a>
              </li>
              <li class="list-unstyled item-contact">
                <a to="#">
                  <mdb-icon icon="map-marker-alt" class="mr-2"></mdb-icon>
                  Adresse physique
                </a>
              </li>
            </ul>
          </mdb-col>
          <mdb-col md="3">
            <h5 class="title">Suivez - nous sur</h5>
            <ul class="list-unstyled list-inline list-social-net">
              <li class="list-inline-item">
                <a class="btn-floating btn-sm btn-fb mx-1"
                  ><i class="fab fa-facebook"> </i
                ></a>
              </li>
              <li class="list-inline-item">
                <a class="btn-floating btn-sm btn-tw mx-1"
                  ><i class="fab fa-twitter"> </i
                ></a>
              </li>
              <li class="list-inline-item">
                <a class="btn-floating btn-sm btn-li mx-1"
                  ><i class="fab fa-linkedin-in"> </i
                ></a>
              </li>
            </ul>
          </mdb-col>
        </mdb-row>
      </mdb-col>
      <hr class="sep-footer" />
    </mdb-container>
    <div class="footer-copyright text-center py-3">
      <mdb-col md="12">
        <mdb-container fluid>
          &copy; {{ new Date().getFullYear() }} Maztou
        </mdb-container>
      </mdb-col>
    </div>
  </mdb-footer>
  <!-- Footer -->
</template>

<script>
import { mdbFooter, mdbContainer, mdbRow, mdbCol, mdbIcon } from "mdbvue";
export default {
  name: "FooterPage",
  components: {
    mdbFooter,
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbIcon,
  },
  data() {
    return {
      imgPath: "images/",
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
