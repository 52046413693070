<template>
  <div style="min-height: 50.2vh;">
    <section
      class="login-container py-4"
      :style="'background: url(' + imgPath + 'bkg-login.png) no-repeat center;'"
    >
      <mdb-container>
        <mdb-col md="12" class="alg-container">
          <mdb-col md="12" class="form-login-container">
            <section class="form-elegant">
              <mdb-row class="justify-content-center">
                <mdb-col md="5">
                  <mdb-card>
                    <mdb-card-body class="mx-4 pb-0">
                      <form @submit.prevent="login">
                        <div class="text-center">
                          <h3 class="dark-grey-text mb-3">
                            <strong>Connexion</strong>
                          </h3>

                          <mdb-alert
                            v-if="message"
                            color="danger"
                            class="d-flex py-1"
                          >
                            <strong class="ftz-13"> {{ message }}</strong>
                          </mdb-alert>

                          <small></small>
                        </div>
                        <mdb-input
                          v-model="user.email"
                          @input="clearMessage"
                          label="E-mail"
                          type="email"
                          outline
                        />
                        <mdb-input
                          v-model="user.password"
                          @input="clearMessage"
                          label="Mot de passe"
                          type="password"
                          containerClass="mb-0"
                          outline
                          class="mb-0"
                        />
                        <p
                          class="cnt-link-form  d-flex justify-content-end pb-0 mb-1 ftz-13"
                        >
                          <a href="#" class="grey-text font-weight-bold ml-1">
                            Mot de passe oublié ?</a
                          >
                        </p>
                        <div class="text-center my-3">
                          <mdb-btn
                            type="submit"
                            gradient="blue"
                            :disabled="submitState.disabled"
                            rounded
                            class="btn-block  btn-theme"
                            >{{ submitState.text }}
                            <mdb-spinner
                              v-if="submitState.loader"
                              small
                              color="red"
                            />
                          </mdb-btn>
                        </div>
                      </form>
                      <hr class="sep-form" />
                      <p
                        class="dark-grey-text text-right d-flex justify-content-center mb-3"
                      ></p>
                      <div class="row my-3 d-flex justify-content-center">
                        <mdb-btn
                          type="button"
                          color="none"
                          rounded
                          class="btn-social-net mr-md-3 z-depth-1a"
                        >
                          <img :src="imgPath + 'google.png'" alt="" />
                        </mdb-btn>
                        <mdb-btn
                          type="button"
                          color="none"
                          rounded
                          class="btn-social-net mr-md-3 z-depth-1a"
                        >
                          <img :src="imgPath + 'twitter.png'" alt="" />
                        </mdb-btn>
                        <mdb-btn
                          type="button"
                          color="none"
                          rounded
                          class="btn-social-net z-depth-1a"
                        >
                          <img :src="imgPath + 'facebook.png'" alt="" />
                        </mdb-btn>
                      </div>
                    </mdb-card-body>
                    <mdb-modal-footer class="mx-5 pt-3 mb-1">
                      <p
                        class="cnt-link-form grey-text d-flex justify-content-center ftz-13"
                      >
                        Pas encore membre?
                        <router-link
                          :to="{ name: 'register' }"
                          class="grey-text font-weight-bold ml-1"
                        >
                          Créer un compte</router-link
                        >
                      </p>
                    </mdb-modal-footer>
                  </mdb-card>
                </mdb-col>
              </mdb-row>
            </section>
          </mdb-col>
        </mdb-col>
      </mdb-container>
    </section>
  </div>
</template>

<script>
import {
  mdbContainer,
  mdbCol,
  mdbRow,
  mdbCard,
  mdbCardBody,
  mdbInput,
  mdbBtn,
  mdbModalFooter,
  mdbSpinner,
  mdbAlert,
} from "mdbvue";
export default {
  name: "loginPage",
  components: {
    mdbContainer,
    mdbCol,
    mdbRow,
    mdbCard,
    mdbCardBody,
    mdbInput,
    mdbBtn,
    mdbModalFooter,
    mdbSpinner,
    mdbAlert,
  },
  data() {
    return {
      imgPath: "images/",
      user: {
        email: null,
        password: null,
      },
      submitState: {
        loader: false,
        disabled: false,
        text: "Se connecter",
      },
      message: "",
    };
  },
  methods: {
    clearMessage() {
      this.message = "";
    },
    login() {
      this.updateSubmitState(true, true, "");
      const user = this.user;
      if (user.email !== null && user.password !== null) {
        this.$store.dispatch("auth/userLogin", { user }).then((response) => {
          if (response.error) {
            this.message = response.error.message;
            this.updateSubmitState(false, false, "Se connecter");
          } else {
            location.reload();
          }
        });
      } else {
        this.updateSubmitState(false, false, "Se connecter");
        this.message = "Tous les champs sont obligatoires";
      }
    },
    updateSubmitState(loader, disabled, text) {
      return (this.submitState = {
        loader: loader,
        disabled: disabled,
        text: text,
      });
    },
  },
};
</script>

<style scoped></style>
