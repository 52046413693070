<template>
  <div>
    <section
      class="top-search-dishes"
      :style="
        'background: url(' +
          imgPath +
          'lasagnes-aux-courgettes-et-au-chevre.jpg) no-repeat center;'
      "
    >
      <mdb-container class="w-100 h-100 index-5">
        <mdb-col class="alg-search-container w-100 h-100 d-flex">
          <div class="cnt-div-flex">
            <div class="top-form-title">
              <h4 class="title-form">
                Vos plats à proximité, <br />
                <span>
                  trouvés facilement
                </span>
              </h4>
            </div>

            <div class="search-form-container">
              <form action="" class="d-inline-flex">
                <mdb-row>
                  <mdb-col md="3">
                    <mdb-input basic class="m-0 item-form">
                      <span class="input-group-text" slot="prepend">
                        <mdb-icon icon="utensils"></mdb-icon>
                      </span>
                      <span class="input-group-text" slot="append"
                        ><mdb-icon icon="map-marker-alt"></mdb-icon
                      ></span>
                    </mdb-input>
                  </mdb-col>
                  <mdb-col md="2">
                    <mdb-input basic class="m-0 item-form">
                      <span class="input-group-text small-font" slot="prepend"
                        >Prix <br />
                        min
                      </span>
                      <span class="input-group-text" slot="append"
                        ><mdb-icon icon="euro-sign"></mdb-icon
                      ></span>
                    </mdb-input>
                  </mdb-col>
                  <mdb-col md="2">
                    <mdb-input basic class="m-0 item-form">
                      <span class="input-group-text small-font" slot="prepend"
                        >Prix <br />
                        max
                      </span>
                      <span class="input-group-text" slot="append"
                        ><mdb-icon icon="euro-sign"></mdb-icon
                      ></span>
                    </mdb-input>
                  </mdb-col>

                  <mdb-col md="3" class="item-form">
                    <mdb-btn color="" class="m-0" rounded>
                      Rechercher
                    </mdb-btn>
                  </mdb-col>
                </mdb-row>
              </form>
            </div>
          </div>
        </mdb-col>
      </mdb-container>
    </section>

    <section class="product-list">
      <mdb-container>
        <mdb-col md="12" class="alg-list-cat-item">
          <div class="cnt-title-list-item">
            <h5 class="title-list-item d-flex align-items-center">
              Catégorie

              <div class="cnt-lnk-all-cat">
                <router-link
                  :to="{ name: 'categorielist' }"
                  class="lnk-all-cat"
                >
                  Toutes les catégories
                </router-link>
              </div>
            </h5>
          </div>

          <mdb-row>
            <div class="cnt-item-slide w-100">
              <carousel
                v-if="categories.length > 0"
                :perPage="5"
                :scrollPerPage="true"
                :navigationEnabled="true"
                :autoplayHoverPause="true"
                :paginationEnabled="false"
                navigationNextLabel='<i class="fas fa-arrow-right"></i>'
                navigationPrevLabel='<i class="fas fa-arrow-left"></i>'
              >
                <slide
                  v-for="(category, i) in categories"
                  :key="i"
                  class="item-carousel"
                >
                  <mdb-card :key="i">
                    <mdb-card-image
                      :src="`data:image/png;base64, ` + category.logo"
                      alt="Card image cap"
                    >
                    </mdb-card-image>

                    <mdb-card-text>{{ category.name }}</mdb-card-text>
                  </mdb-card>
                </slide>
              </carousel>
              <carousel v-else :perPage="5">
                <slide v-for="index in 5" :key="index" class="item-carousel">
                  <mdb-card :key="index">
                    <vue-content-loading :width="300" :height="300">
                      <rect
                        x="0"
                        y="0"
                        rx="25"
                        ry="25"
                        width="300"
                        height="250"
                      ></rect
                      ><rect
                        x="65"
                        y="255"
                        rx="8"
                        ry="8"
                        width="175"
                        height="25"
                      ></rect>
                    </vue-content-loading>
                  </mdb-card>
                </slide>
              </carousel>
            </div>
          </mdb-row>
        </mdb-col>

        <mdb-col md="12" class="alg-list-cat-item pt-4">
          <div class="cnt-title-list-item">
            <h5 class="title-list-item">Diététique</h5>
          </div>
          <mdb-row>
            <div class="cnt-item-slide w-100">
              <carousel
                v-if="diatetics.length > 0"
                :perPage="5"
                :scrollPerPage="true"
                :navigationEnabled="true"
                :autoplayHoverPause="true"
                :paginationEnabled="false"
                navigationNextLabel='<i class="fas fa-arrow-right"></i>'
                navigationPrevLabel='<i class="fas fa-arrow-left"></i>'
              >
                <slide
                  v-for="(category, i) in diatetics"
                  :key="i"
                  class="item-carousel"
                >
                  <mdb-card :key="i">
                    <mdb-card-image
                      :src="`data:image/png;base64, ` + category.logo"
                      alt="Card image cap"
                    >
                    </mdb-card-image>

                    <mdb-card-text>{{ category.name }}</mdb-card-text>
                  </mdb-card>
                </slide>
              </carousel>
              <carousel v-else :perPage="5">
                <slide v-for="index in 5" :key="index" class="item-carousel">
                  <mdb-card :key="index">
                    <vue-content-loading :width="300" :height="300">
                      <rect
                        x="0"
                        y="0"
                        rx="25"
                        ry="25"
                        width="300"
                        height="250"
                      ></rect
                      ><rect
                        x="65"
                        y="255"
                        rx="8"
                        ry="8"
                        width="175"
                        height="25"
                      ></rect>
                    </vue-content-loading>
                  </mdb-card>
                </slide>
              </carousel>
            </div>
          </mdb-row>
        </mdb-col>

        <mdb-col md="12" class="alg-list-cat-item pt-4">
          <div class="cnt-title-list-item">
            <h5 class="title-list-item">Les restaurants avec de bonne Note</h5>
          </div>

          <div class="cnt-good-mark w-100">
            <mdb-row class="pt-4" v-if="markrating.length > 0">
              <mdb-col
                md="6"
                class="item-mark"
                v-for="(mark, mr) in markrating"
                :key="mr"
              >
                <mdb-alert color="light" class="d-flex">
                  <div class="cnt-mark-logo">
                    <img :src="`data:image/png;base64, ` + mark.cover" alt="" />
                  </div>
                  <div class="cnt-about-mark">
                    <div class="top-mark-name">
                      <h6>
                        <router-link
                          :to="{
                            name: 'categoryDetail',
                            params: { ID: mark._id },
                          }"
                          >{{ mark.name }}</router-link
                        >
                      </h6>
                      <p>
                        Pizza - Burger - Frite
                      </p>
                    </div>
                    <div class="cnt-mark-location">
                      <p class="m-0 d-flex  align-items-center">
                        à 800 m - Restaurant
                        <span class="rating">
                          <mdb-icon icon="star" class="mr-1"></mdb-icon> 4.5
                        </span>
                      </p>
                    </div>
                  </div>
                </mdb-alert>
              </mdb-col>
            </mdb-row>
            <mdb-row class="pt-4" v-else>
              <mdb-col md="6" class="item-mark" v-for="index in 4" :key="index">
                <mdb-alert color="light" class="d-flex">
                  <!-- <vcl-instagram :height="100"></vcl-instagram> -->
                  <vue-content-loading
                    style="width: 86%;"
                    :width="450"
                    :height="93"
                  >
                    <rect
                      x="5"
                      y="5"
                      rx="4"
                      ry="4"
                      width="100"
                      height="82"
                    ></rect
                    ><rect x="115" y="5" rx="4" ry="4" width="175" height="10">
                    </rect>

                    <rect
                      x="115"
                      y="25"
                      rx="4"
                      ry="4"
                      width="175"
                      height="10"
                    ></rect>

                    <rect
                      x="115"
                      y="55"
                      rx="4"
                      ry="4"
                      width="175"
                      height="10"
                    ></rect>
                  </vue-content-loading>
                </mdb-alert>
              </mdb-col>
            </mdb-row>
          </div>
        </mdb-col>
      </mdb-container>
    </section>
  </div>
</template>

<script>
import {
  mdbContainer,
  mdbCol,
  mdbInput,
  mdbBtn,
  mdbRow,
  mdbIcon,
  mdbCard,
  mdbCardImage,
  mdbCardText,
  mdbAlert,
} from "mdbvue";
import // VclFacebook,
//VclInstagram,
"vue-content-loading";
import VueContentLoading from "vue-content-loading";
import { mapGetters } from "vuex";

export default {
  name: "homePage",
  components: {
    mdbContainer,
    mdbCol,
    mdbInput,
    mdbBtn,
    mdbRow,
    mdbIcon,
    mdbCard,
    mdbCardImage,
    mdbCardText,
    mdbAlert,
    // VclFacebook,
    //VclInstagram,
    VueContentLoading,
  },
  data() {
    return {
      imgPath: "images/",
      categories: [],
      markrating: [],
      diatetics: [],
      default_access_token:
        localStorage.getItem("default_access_token") || null,
    };
  },
  computed: {
    ...mapGetters("category", ["categorys"]),
    ...mapGetters("etablisment", ["etablisments"]),
  },
  methods: {
    generateDefaultToken() {
      if (
        localStorage.getItem("default_access_token") === null &&
        localStorage.getItem("user_access_token") === null
      ) {
        this.$store.dispatch("auth/defaultLogin").then((response) => {
          return response;
        });
      }
    },
    getAllCategorys() {
      if (this.categorys == null) {
        this.$store.dispatch("category/categorys").then((response) => {
          if (response.data) {
            const categorys = response.data;
            this.dispatchData(categorys, this.categories, 0);
            this.dispatchData(categorys, this.diatetics, 1);
          }
        });
      } else {
        this.dispatchData(this.categorys, this.categories, 0);
        this.dispatchData(this.categorys, this.diatetics, 1);
      }
    },
    dispatchData(array, object, key) {
      array.map((data) => {
        if (data.type === key) {
          object.push(data);
        }
      });
    },
    getAllEtablisments() {
      if (this.etablisments == null) {
        this.$store.dispatch("etablisment/etablisments").then((response) => {
          this.markrating = response;
        });
      } else {
        this.markrating = this.etablisments;
      }
    },
  },
  mounted() {
    this.generateDefaultToken();
    this.getAllCategorys();
    this.getAllEtablisments();
    // this.getdistance();
  },
};
</script>
