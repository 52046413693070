<template>
  <div>
    <!-- modal about -->
    <mdb-modal class="modal-about" :show="modal" @close="modal = false">
      <mdb-modal-header>
        <mdb-modal-title>Filtre</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col md="12" class="cnt-title-product">
            <h6
              class="title-product d-flex align-items-center justify-content-space-between"
            >
              Utiliser la géolocalisation
              <mdb-switch v-model="switchValue" class="switch-no-label" />
            </h6>
          </mdb-col>
          <mdb-col md="12">
            <hr />
          </mdb-col>
          <div class="search-form-container popup w-100">
            <mdb-col md="12" class="cnt-title-product py-0">
              <h6
                class="title-product d-flex align-items-center justify-content-space-between mb-2"
              >
                Fourchette de prix
              </h6>
            </mdb-col>

            <mdb-col md="12">
              <form action="" class="">
                <mdb-row>
                  <mdb-col md="6">
                    <mdb-input basic class="m-0 item-form" v-model="minPrice">
                      <span class="input-group-text small-font" slot="prepend"
                        >Min
                      </span>
                      <span class="input-group-text" slot="append"
                        ><mdb-icon icon="euro-sign"></mdb-icon
                      ></span>
                    </mdb-input>
                  </mdb-col>
                  <mdb-col md="6">
                    <mdb-input basic class="m-0 item-form" v-model="maxPrice">
                      <span class="input-group-text small-font" slot="prepend"
                        >Max
                      </span>
                      <span class="input-group-text" slot="append"
                        ><mdb-icon icon="euro-sign"></mdb-icon
                      ></span>
                    </mdb-input>
                  </mdb-col>
                </mdb-row>
              </form>
            </mdb-col>
          </div>
          <mdb-col md="12">
            <hr />
          </mdb-col>
          <mdb-col md="12" class="cnt-title-product">
            <h6
              class="title-product d-flex align-items-center justify-content-space-between"
            >
              Trie titres restaurant
              <mdb-switch v-model="switchValue2" class="switch-no-label" />
            </h6>
          </mdb-col>

          <mdb-col md="12">
            <hr />
          </mdb-col>
        </mdb-row>

        <mdb-row>
          <mdb-col md="12" class="cnt-title-product pb-0">
            <h6
              class="title-product d-flex align-items-center justify-content-space-between mb-2"
            >
              Diététique
            </h6>
          </mdb-col>

          <mdb-col md="6">
            <mdb-input
              type="checkbox"
              id="checkbox1"
              name="check1"
              v-model="check1"
              label="Végétarien"
              class="p-0"
            />
          </mdb-col>

          <mdb-col md="6">
            <mdb-input
              type="checkbox"
              id="checkbox2"
              name="check2"
              v-model="check2"
              label="Vegan"
              class="p-0"
            />
          </mdb-col>
          <mdb-col md="12" class="px-0 my-2 sep-check"></mdb-col>
          <mdb-col md="6">
            <mdb-input
              type="checkbox"
              disabled
              id="checkbox3"
              name="check3"
              v-model="check3"
              label="Sans gluten"
              class="p-0"
            />
          </mdb-col>

          <mdb-col md="6">
            <mdb-input
              type="checkbox"
              disabled
              id="checkbox4"
              name="check4"
              v-model="check4"
              label="Sans porc"
              class="p-0"
            />
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer class="text-center">
        <mdb-btn color="yellow" class="save-change">Appliquer filtre</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- modal about -->

    <section class="cnt-all-categories pt-4">
      <mdb-container>
        <mdb-col md="12" class="alg-container">
          <div class="cnt-top-crumbs">
            <mdb-breadcrumb class="main-crumbs">
              <mdb-breadcrumb-item
                ><router-link :to="{ name: 'home' }"
                  >Accueil</router-link
                ></mdb-breadcrumb-item
              >
              <mdb-breadcrumb-item active>Catégories</mdb-breadcrumb-item>
            </mdb-breadcrumb>
          </div>
        </mdb-col>

        <mdb-col md="12" class="alg-list-cat-item pt-4">
          <div class="cnt-title-list-item">
            <h5 class="title-list-item d-flex align-items-center cat">
              Pizza
              <div class="cnt-select-cat">
                <mdb-btn
                  icon="sliders-h"
                  @click.native="modal = true"
                  class="main-drop-cat"
                ></mdb-btn>
              </div>
            </h5>
          </div>

          <div class="cnt-good-mark w-100">
            <mdb-row class="pt-4">
              <mdb-col
                md="6"
                class="item-mark"
                v-for="(mark, mr) in markrating"
                :key="mr"
              >
                <mdb-alert color="light" class="d-flex">
                  <div class="cnt-mark-logo">
                    <img :src="imgPath + 'logos/' + mark.img" alt="" />
                  </div>
                  <div class="cnt-about-mark">
                    <div class="top-mark-name">
                      <h6>
                        <router-link
                          :to="{
                            name: 'categoryDetail',
                            params: { ID: mark.id },
                          }"
                        >
                          {{ mark.name }}
                        </router-link>
                      </h6>
                      <p>
                        Pizza - Burger - Frite
                      </p>
                    </div>
                    <div class="cnt-mark-location">
                      <p class="m-0 d-flex  align-items-center">
                        à 800 m - Restaurant
                        <span class="rating">
                          <mdb-icon icon="star" class="mr-1"></mdb-icon> 4.5
                        </span>
                      </p>
                    </div>
                  </div>
                </mdb-alert>
              </mdb-col>
            </mdb-row>
          </div>
        </mdb-col>
      </mdb-container>
    </section>
  </div>
</template>

<script>
import {
  mdbContainer,
  mdbRow,
  mdbCol,
  mdbBreadcrumb,
  mdbBreadcrumbItem,
  mdbAlert,
  mdbIcon,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
  mdbBtn,
  mdbSwitch,
  mdbInput,
} from "mdbvue";
export default {
  name: "categorielist",
  components: {
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbBreadcrumb,
    mdbBreadcrumbItem,
    mdbAlert,
    mdbIcon,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbBtn,
    mdbSwitch,
    mdbInput,
  },
  data() {
    return {
      imgPath: "images/",
      markrating: [
        {
          name: "Chez bon pizza",
          img: "bon-pizza.png",
          id: "aze54138fsgfh",
        },
        {
          name: "Za te ipizza",
          img: "pates.png",
          id: "aze54138fsgfh",
        },
        {
          name: "Pizza-ka",
          img: "pizza.png",
          id: "aze54138fsgfh",
        },
        {
          name: "Good-pizza",
          img: "bon-pizza.png",
          id: "aze54138fsgfh",
        },
        {
          name: "Chez bon pizza",
          img: "bon-pizza.png",
          id: "aze54138fsgfh",
        },
        {
          name: "Za te ipizza",
          img: "pates.png",
          id: "aze54138fsgfh",
        },
        {
          name: "Pizza-ka",
          img: "pizza.png",
          id: "aze54138fsgfh",
        },
        {
          name: "Good-pizza",
          img: "bon-pizza.png",
          id: "aze54138fsgfh",
        },
      ],
      basicOptions: [
        { text: "Option nr 1", value: "Option 1" },
        { text: "Option nr 2", value: "Option 2" },
        { text: "Option nr 3", value: "Option 3" },
      ],
      modal: false,
      switchValue: false,
      switchValue2: false,
      minPrice: 0,
      maxPrice: 0,
      check1: "",
      check2: "",
      check3: true,
      check4: true,
    };
  },
  watch: {
    modal: function() {
      let el = document.body;
      if (this.modal === true) {
        el.classList.add("hidden-bar");
      } else {
        el.classList.remove("hidden-bar");
      }
    },
  },
};
</script>

<style scoped></style>
