import Vue from 'vue'
import Router from 'vue-router'
import HomeContainer from '@/components/front/HomeContainer'
import Home from '@/components/front/home'
import CategorieList from '@/components/front/CategorieList'
import DetailCategory from '@/components/front/DetailCategory'
import SingleProduct from '@/components/front/SingleProduct'
import cartPage from '@/components/front/cartPage'
import ordersPage from '@/components/front/ordersPage'
import loginPage from '@/components/front/signin'
import registerPage from '@/components/front/signup'
import accountContainer from '@/components/front/accountContainer'
import userAccount from '@/components/front/userAccount'
import ftRegister from '@/components/front/ftRegister'
import editeProfileFt from '@/components/front/editeProfileFt'
import dishesPage from '@/components/front/plats'
import HomeDashboard from '@/components/dashboards/v-1'
import DashboardContainer from '@/components/dashboards/DashboardContainer'

Vue.use(Router)

const router = new Router({
    routes: [{
            path: '/',
            component: HomeContainer,
            children: [{
                    path: '',
                    name: 'home',
                    component: Home
                },
                {
                    path: 'categories',
                    name: 'categorielist',
                    component: CategorieList
                },
                {
                    path: 'categorie/:ID',
                    name: 'categoryDetail',
                    component: DetailCategory
                },
                {
                    path: 'categorie/:ID/:idProduct',
                    name: 'detailProduct',
                    component: SingleProduct
                },
                {
                    path: 'cart/',
                    name: 'panier',
                    component: cartPage
                },
                {
                    path: 'orders/',
                    name: 'commande',
                    component: ordersPage
                },
                {
                    path: 'signin/',
                    name: 'login',
                    component: loginPage,
                    meta: {
                        guest: true
                    }
                },
                {
                    path: 'signup/',
                    name: 'register',
                    component: registerPage,
                    meta: {
                        guest: true
                    }
                },
                {
                    path: 'account/',
                    component: accountContainer,
                    children: [{
                            path: '',
                            name: 'account',
                            component: userAccount
                        },
                        {
                            path: 'settings',
                            name: 'editeFt',
                            component: editeProfileFt
                        }
                    ],
                    meta: {
                        // requiredAuth: true
                    }
                },
                {
                    path: 'ft-register/',
                    name: 'inscft',
                    component: ftRegister
                },
                {
                    path: 'dishes',
                    name: 'plats',
                    component: dishesPage
                }
            ]
        },


        // dashboard route


        {
            path: '/panel/',
            component: DashboardContainer,
            children: [{
                path: '',
                name: 'dashboardHome',
                component: HomeDashboard
            }]
        },

    ]
})

//admin-router set up  security
router.beforeEach((to, from, next) => {
    if (to.matched.some((recored) => recored.meta.requiredAuth)) {
        if (localStorage.getItem('user_access_token') == null) {
            next({
                name: 'login',
                params: { nextUrl: to.fullPath }
            })
        } else {
            next()
        }
    } else if (to.matched.some((record) => record.meta.guest)) {
        if (localStorage.getItem("user_access_token") == null) {
            next();
        } else {
            next({ name: "account" });
        }
    } else {
        next();
    }
})

export default router