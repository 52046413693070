import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import sidenav from './utils/Sidenav'
import Auth from '../store/modules/Login'
import Categorys from '../store/modules/Category'
import Etablisments from '../store/modules/Etablisment'
axios.defaults.baseURL = 'https://maztou-api-h34nnnnjoa-uc.a.run.app/api'

if (localStorage.getItem('default_access_token') !== null && localStorage.getItem('user_access_token') == null) {
    axios.defaults.headers.common['Authorization'] = localStorage.getItem('default_access_token')
} else {
    axios.defaults.headers.common['Authorization'] = localStorage.getItem('user_access_token')
}

Vue.use(Vuex)


const store = new Vuex.Store({
    modules: {
        sidenav: sidenav,
        auth: Auth,
        category: Categorys,
        etablisment: Etablisments
    }
})

export default store