<template>
  <div style="min-height: 50.2vh;">
    <section class="top-mark-banier pt-4">
      <mdb-container>
        <mdb-col md="12" class="alg-container">
          <div class="cnt-top-crumbs">
            <mdb-breadcrumb class="main-crumbs">
              <mdb-breadcrumb-item
                ><router-link :to="{ name: 'home' }"
                  >Accueil</router-link
                ></mdb-breadcrumb-item
              >
              <mdb-breadcrumb-item active>
                Inscription restaurant ou food truck</mdb-breadcrumb-item
              >
            </mdb-breadcrumb>
          </div>

          <mdb-row class="col-cart-container pt-2">
            <mdb-col md="12" class="px-0">
              <mdb-col md="12" class="col-cart-title-containe">
                <h5 class="cart-title">
                  Inscription restaurant ou food truck
                </h5>
                <p class="sub-title-item m-0">
                  Pour créer un compte, veuillez nous renseigner
                </p>
              </mdb-col>
            </mdb-col>
            <mdb-col md="7" class="col-cart cart-total-pay">
              <mdb-col md="12" class="px-0">
                <mdb-col md="12" class="form-login-container px-0">
                  <section class="form-elegant">
                    <mdb-row class="justify-content-center">
                      <mdb-col md="6" class="item-form-control-container">
                        <mdb-select
                          v-model="basicOptions"
                          placeholder="Type*"
                          label=""
                          outline
                        />
                      </mdb-col>

                      <mdb-col md="6" class="item-form-control-container">
                        <mdb-input
                          label="Nom complet du gestionnaire*"
                          type="text"
                          containerClass="mb-0"
                          outline
                          class="mb-0"
                        />
                      </mdb-col>
                    </mdb-row>
                    <mdb-row class="justify-content-center">
                      <mdb-col md="6" class="item-form-control-container">
                        <mdb-input
                          label="Nom de l’établissement*"
                          type="text"
                          outline
                        />
                      </mdb-col>

                      <mdb-col md="6" class="item-form-control-container">
                        <mdb-input
                          label="Adresse du gestionnaire*"
                          type="text"
                          containerClass="mb-0"
                          outline
                          class="mb-0"
                        />
                      </mdb-col>
                    </mdb-row>
                    <mdb-row class="justify-content-center">
                      <mdb-col md="6" class="item-form-control-container">
                        <mdb-input
                          label="Adresse de l’établissement*"
                          type="text"
                          outline
                        />
                      </mdb-col>

                      <mdb-col md="6" class="item-form-control-container">
                        <mdb-input
                          label="Numéros de téléphone*"
                          type="text"
                          containerClass="mb-0"
                          outline
                          class="mb-0"
                        />
                      </mdb-col>
                    </mdb-row>
                    <mdb-row class="justify-content-center">
                      <mdb-col md="6" class="item-form-control-container">
                        <mdb-input
                          label="Adresse de l’établissement*"
                          type="text"
                          containerClass="mb-0"
                          outline
                          class="mb-0"
                        />
                      </mdb-col>

                      <mdb-col md="6" class="item-form-control-container">
                        <mdb-input
                          label="Information bancaire “IBAN”*"
                          type="text"
                          outline
                        />
                      </mdb-col>
                    </mdb-row>
                    <mdb-row class="justify-content-center">
                      <mdb-col md="6" class="item-form-control-container">
                        <mdb-form-inline>
                          <mdb-input
                            type="checkbox"
                            id="checkbox5"
                            label="Commission 8%"
                          />
                        </mdb-form-inline>
                      </mdb-col>

                      <mdb-col md="6" class="item-form-control-container">
                      </mdb-col>
                    </mdb-row>
                  </section>
                </mdb-col>
              </mdb-col>
            </mdb-col>
            <mdb-col md="5" class="right-avatar-container text-center">
              <div class="cnt-title-avatar">
                <h6 class="title-avatar  mb-0">
                  Votre logo
                </h6>
              </div>

              <div class="cnt-int-avatar">
                <div class="avatar">
                  <mdb-icon icon="image" v-if="!image"></mdb-icon>
                  <img :src="imgPath + 'logo.png'" alt="" v-if="image" />
                  <div class="col cnt-btn-edite-avatar">
                    <mdb-btn icon="pen" class="btn-edite-avatar"></mdb-btn>
                  </div>
                </div>
              </div>

              <div class="text-center my-3">
                <mdb-col md="6" class="mx-auto">
                  <mdb-btn
                    type="button"
                    gradient="blue"
                    rounded
                    class="btn-block  btn-theme"
                    >Créer un compte</mdb-btn
                  >
                </mdb-col>
              </div>
            </mdb-col>
          </mdb-row>
        </mdb-col>
      </mdb-container>
    </section>
  </div>
</template>

<script>
import {
  mdbRow,
  mdbContainer,
  mdbCol,
  mdbBreadcrumb,
  mdbBreadcrumbItem,
  mdbBtn,
  mdbInput,
  mdbSelect,
  mdbFormInline,
  mdbIcon,
} from "mdbvue";
export default {
  name: "SingleProduct",
  components: {
    mdbRow,
    mdbContainer,
    mdbCol,
    mdbBreadcrumb,
    mdbBreadcrumbItem,
    mdbBtn,
    mdbInput,
    mdbSelect,
    mdbFormInline,
    mdbIcon,
  },
  data() {
    return {
      imgPath: "images/",
      basicOptions: [
        { text: "Restaurant", value: "1" },
        { text: "Food truck", value: "2" },
      ],
      radio5: "",
      image: false,
    };
  },
};
</script>

<style scoped></style>
