<template>
  <div style="min-height: 50.2vh;">
    <section class="top-mark-banier pt-4">
      <mdb-container>
        <mdb-col md="12" class="alg-container">
          <div class="cnt-top-crumbs">
            <mdb-breadcrumb class="main-crumbs">
              <mdb-breadcrumb-item
                ><router-link :to="{ name: 'home' }"
                  >Accueil</router-link
                ></mdb-breadcrumb-item
              >
              <mdb-breadcrumb-item active>Commandes</mdb-breadcrumb-item>
            </mdb-breadcrumb>
          </div>

          <!-- Modal item -->

          <mdb-modal
            class="modal-about"
            :show="modalInfo"
            @close="modalInfo = false"
          >
            <mdb-modal-header>
              <mdb-modal-title>A propos </mdb-modal-title>
            </mdb-modal-header>
            <mdb-modal-body>
              <mdb-row>
                <mdb-col md="12" class="cnt-title-product">
                  <h6 class="title-product">
                    Pizza margerita 25cm
                  </h6>
                </mdb-col>

                <mdb-col md="12" class="item-top-info text-left">
                  <h6 class="top-info-ft">
                    <span>Lieu de retrait: <br /></span>
                    Rue de Rivoli 75004
                  </h6>
                </mdb-col>
                <mdb-col md="12" class="item-top-info text-right">
                  <h6 class="top-info-ft">
                    <span
                      >Temps restant avant la prochaine commande possible :<br
                    /></span>
                    4h
                  </h6>
                </mdb-col>
              </mdb-row>

              <hr class="sep-comment my-3" />
              <mdb-row>
                <mdb-col md="12" class="cnt-desc-product">
                  <p class="m-0">
                    Base creme fraiche, emmental, lardons auxchampignons et
                    fondue de poireaux, oignos, pomme de terre
                  </p>
                </mdb-col>
              </mdb-row>
              <hr class="sep-comment my-3" />

              <mdb-row>
                <mdb-col md="12" class="cnt-desc-product">
                  <p class="m-0">
                    - Contient du lait et des produits a base de lait (lactose
                    inclu)
                  </p>
                  <p class="m-0">
                    - Contient du dioxyde de souffre et des sulfites
                  </p>
                </mdb-col>
              </mdb-row>
            </mdb-modal-body>
            <mdb-modal-footer class="text-center">
              <mdb-btn color="yellow" class="save-change"
                >Ajouter au panier</mdb-btn
              >
            </mdb-modal-footer>
          </mdb-modal>

          <!-- Modal item -->
        </mdb-col>
      </mdb-container>
    </section>

    <section class="menu-list-container">
      <mdb-container>
        <mdb-col md="12" class="py-4">
          <mdb-tab tabs color="" class="top-tabs-main">
            <mdb-tab-item
              icon="user"
              :active="pillsActive == 0"
              @click.native.prevent="pillsActive = 0"
              >En cours</mdb-tab-item
            >
            <mdb-tab-item
              icon="heart"
              :active="pillsActive == 1"
              @click.native.prevent="pillsActive = 1"
              >Passées</mdb-tab-item
            >
          </mdb-tab>
          <mdb-tab-content class="tab-container">
            <mdb-tab-pane class="" key="show1" v-if="pillsActive == 0">
              <div class="cnt-good-mark w-100">
                <mdb-row class="pt-4">
                  <mdb-col
                    md="4"
                    class="item-mark product"
                    v-for="(mark, mr) in markrating"
                    :key="mr"
                  >
                    <mdb-alert color="light" class="d-flex">
                      <div class="cnt-mark-logo">
                        <img :src="imgPath + 'plats/' + mark.img" alt="" />
                      </div>
                      <div class="cnt-about-mark">
                        <div class="top-mark-name">
                          <h6>
                            <router-link
                              :to="{
                                name: '',
                                params: { ID: mark.id, idProduct: mark.name },
                              }"
                            >
                              {{ mark.name }}
                            </router-link>
                          </h6>
                          <p class="desc">
                            {{ mark.desc }}
                          </p>
                        </div>
                        <div class="cnt-mark-location">
                          <p class="m-0 d-flex  align-items-center">
                            {{ mark.price }} €
                          </p>
                          <div class="cnt-lnks-title">
                            <mdb-btn
                              @click.native="modalInfo = true"
                              class="mark-info my-0 p-0"
                              icon="exclamation-circle r-180"
                            ></mdb-btn>
                            <mdb-btn
                              class="mark-heart my-0 p-0 mr-0"
                              icon="plus-circle"
                            ></mdb-btn>
                          </div>
                        </div>
                      </div>
                    </mdb-alert>
                  </mdb-col>
                </mdb-row>
              </div>
            </mdb-tab-pane>
            <mdb-tab-pane class="fade" key="show2" v-if="pillsActive == 1">
              <div class="cnt-good-mark w-100">
                <mdb-row class="pt-4">
                  <mdb-col
                    md="4"
                    class="item-mark product"
                    v-for="(mark, mr) in markrating.slice(0, 1)"
                    :key="mr"
                  >
                    <mdb-alert color="light" class="d-flex">
                      <div class="cnt-mark-logo">
                        <img :src="imgPath + 'plats/' + mark.img" alt="" />
                      </div>
                      <div class="cnt-about-mark">
                        <div class="top-mark-name">
                          <h6>
                            <router-link
                              :to="{
                                name: '',
                                params: { ID: mark.id, idProduct: mark.name },
                              }"
                            >
                              {{ mark.name }}
                            </router-link>
                          </h6>
                          <p class="desc">
                            {{ mark.desc }}
                          </p>
                        </div>
                        <div class="cnt-mark-location">
                          <p class="m-0 d-flex  align-items-center">
                            {{ mark.price }} €
                          </p>
                          <div class="cnt-lnks-title">
                            <mdb-btn
                              @click.native="modalInfo = true"
                              class="mark-info my-0 p-0"
                              icon="exclamation-circle r-180"
                            ></mdb-btn>
                            <mdb-btn
                              class="mark-heart my-0 p-0 mr-0"
                              icon="plus-circle"
                            ></mdb-btn>
                          </div>
                        </div>
                      </div>
                    </mdb-alert>
                  </mdb-col>
                </mdb-row>
              </div>
            </mdb-tab-pane>
          </mdb-tab-content>
        </mdb-col>
      </mdb-container>
    </section>
  </div>
</template>

<script>
import {
  mdbCol,
  mdbContainer,
  mdbBreadcrumb,
  mdbBreadcrumbItem,
  mdbBtn,
  mdbAlert,
  mdbRow,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
  mdbTab,
  mdbTabItem,
  mdbTabContent,
  mdbTabPane,
} from "mdbvue";
export default {
  name: "DetailCategory",
  components: {
    mdbCol,
    mdbContainer,
    mdbBreadcrumb,
    mdbBreadcrumbItem,
    mdbBtn,
    mdbAlert,
    mdbRow,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbTab,
    mdbTabItem,
    mdbTabContent,
    mdbTabPane,
  },
  data() {
    return {
      imgPath: "images/",
      productName: "",
      markrating: [
        {
          name: "Pizza margerita 25cm",
          img: "bread-delicious-eggg-704569.png",
          id: "aze54138fsgfh",
          desc:
            "Base creme fraiche, emmental, lardons auxchampignons et fondue de poireaux, oignos, pomme de terre",
          price: "8.00",
        },
        {
          name: "Pizza margerita 25cm",
          img: "bread-delicious-egg-704569.png",
          id: "aze54138fsgfh",
          desc:
            "Base creme fraiche, emmental, lardons auxchampignons et fondue de poireaux, oignos, pomme de terre",
          price: "7.50",
        },
      ],
      modal: false,
      modalInfo: false,
      value: 5,
      value2: 4,
      value3: 0,
      isModalVisible: false,
      pillsActive: 0,
      verticalWithin: 0,
    };
  },
  watch: {
    modal: function() {
      let el = document.body;
      if (this.modal === true) {
        el.classList.add("hidden-bar");
      } else {
        el.classList.remove("hidden-bar");
      }
    },
    modalInfo: function() {
      let el = document.body;
      if (this.modalInfo === true) {
        el.classList.add("hidden-bar");
      } else {
        el.classList.remove("hidden-bar");
      }
    },
  },
};
</script>
