<template>
  <div style="background-color: #fff;">
    <homenavbar />
    <router-view></router-view>
    <footercopy />
  </div>
</template>

<script>
export default {
  name: "HomeContainer",
};
</script>

<style scoped></style>
