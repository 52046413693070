<template>
  <div style="min-height: 50.2vh;">
    <section class="top-mark-banier pt-4">
      <mdb-container>
        <mdb-col md="12" class="alg-container">
          <div class="cnt-top-crumbs">
            <mdb-breadcrumb class="main-crumbs">
              <mdb-breadcrumb-item
                ><router-link :to="{ name: 'home' }"
                  >Accueil</router-link
                ></mdb-breadcrumb-item
              >
              <mdb-breadcrumb-item active> Inscription</mdb-breadcrumb-item>
            </mdb-breadcrumb>
          </div>

          <mdb-row class="col-cart-container pt-2">
            <mdb-col md="4" class="col-cart list-in-cart">
              <mdb-col md="12" class="alg-int-col-cart pr-0">
                <mdb-col md="12" class="col-cart-title-container px-0">
                  <h5 class="cart-title">
                    Créer un compte en utilisant
                  </h5>
                </mdb-col>

                <div class="row my-3 d-flex justify-content-start">
                  <mdb-btn
                    type="button"
                    color="none"
                    rounded
                    class="btn-social-net mr-md-3 z-depth-1a"
                  >
                    <img :src="imgPath + 'google.png'" alt="" />
                  </mdb-btn>
                  <mdb-btn
                    type="button"
                    color="none"
                    rounded
                    class="btn-social-net mr-md-3 z-depth-1a"
                  >
                    <img :src="imgPath + 'twitter.png'" alt="" />
                  </mdb-btn>
                  <mdb-btn
                    type="button"
                    color="none"
                    rounded
                    class="btn-social-net z-depth-1a"
                  >
                    <img :src="imgPath + 'facebook.png'" alt="" />
                  </mdb-btn>
                </div>
              </mdb-col>
            </mdb-col>
            <mdb-col md="8" class="col-cart cart-total-pay brd-left">
              <mdb-col md="10" class="">
                <mdb-col md="12" class="col-cart-title-container px-0">
                  <h5 class="cart-title">
                    Ou en remplissant les champs
                  </h5>
                </mdb-col>

                <mdb-col md="12" class="form-login-container px-0">
                  <section class="form-elegant">
                    <mdb-row class="justify-content-center">
                      <mdb-col md="6" class="item-form-control-container">
                        <mdb-input label="Nom*" type="text" outline />
                      </mdb-col>

                      <mdb-col md="6" class="item-form-control-container">
                        <mdb-input
                          label="Prénom(s)*"
                          type="text"
                          containerClass="mb-0"
                          outline
                          class="mb-0"
                        />
                      </mdb-col>
                    </mdb-row>
                    <mdb-row class="justify-content-center">
                      <mdb-col md="6" class="item-form-control-container">
                        <mdb-input label="Email*" type="email" outline />
                      </mdb-col>

                      <mdb-col md="6" class="item-form-control-container">
                        <mdb-input
                          label="Adresse*"
                          type="text"
                          containerClass="mb-0"
                          outline
                          class="mb-0"
                        />
                      </mdb-col>
                    </mdb-row>
                    <mdb-row class="justify-content-center">
                      <mdb-col md="6" class="item-form-control-container">
                        <mdb-input
                          label="Mot de passe*"
                          type="password"
                          outline
                        />
                      </mdb-col>

                      <mdb-col md="6" class="item-form-control-container">
                        <mdb-input
                          label="Confirmer mot de passe*"
                          type="password"
                          outline
                        />
                      </mdb-col>
                    </mdb-row>
                    <div class="text-center my-3">
                      <mdb-col md="6" class="mx-auto">
                        <mdb-btn
                          type="button"
                          gradient="blue"
                          rounded
                          class="btn-block  btn-theme"
                          >Créer un compte</mdb-btn
                        >
                      </mdb-col>
                    </div>
                  </section>
                </mdb-col>
              </mdb-col>
            </mdb-col>
          </mdb-row>
        </mdb-col>
      </mdb-container>
    </section>
  </div>
</template>

<script>
import {
  mdbRow,
  mdbContainer,
  mdbCol,
  mdbBreadcrumb,
  mdbBreadcrumbItem,
  mdbBtn,
  mdbInput,
} from "mdbvue";
export default {
  name: "SingleProduct",
  components: {
    mdbRow,
    mdbContainer,
    mdbCol,
    mdbBreadcrumb,
    mdbBreadcrumbItem,
    mdbBtn,
    mdbInput,
  },
  data() {
    return {
      imgPath: "images/",
    };
  },
};
</script>

<style scoped></style>
