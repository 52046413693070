<template>
  <div>
    <!--Navbar-->
    <navbar :page="activePage" />
    <!--/Navbar-->
    <!--SideNav-->
    <sidenav class="white-skin" />
    <!--/SideNav-->

    <main>
      <router-view></router-view>
    </main>
  </div>
</template>

<script>
export default {
  name: "DashboardContainer",
};
</script>

<style scoped></style>
