import axios from 'axios'

const state = () => ({
    etablisments: null
})

const getters = {
    etablisments: state => state.etablisments
}

const actions = {
    etablisments: (context) => {
        return new Promise((resolve, reject) => {
            axios.get('/etablishment').then(response => {
                if (response.data) {
                    const data = response.data
                    context.commit('etablisments', data)
                    resolve(data)
                }
            }).catch(err => {
                reject(err)
            })
        })
    }
}

const mutations = {
    etablisments: (state, etablisments) => {
        return state.etablisments = etablisments
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}