<template>
  <div style="min-height: 50.2vh;">
    <section class="top-mark-banier pt-4">
      <mdb-container>
        <mdb-col md="12" class="alg-container">
          <div class="cnt-top-crumbs">
            <mdb-breadcrumb class="main-crumbs">
              <mdb-breadcrumb-item
                ><router-link :to="{ name: 'home' }"
                  >Accueil</router-link
                ></mdb-breadcrumb-item
              >
              <mdb-breadcrumb-item active> Mon compte</mdb-breadcrumb-item>
            </mdb-breadcrumb>
          </div>

          <mdb-row>
            <mdb-col md="8" class="">
              <mdb-row>
                <mdb-col md="6" class="left-about-user-container">
                  <div class="cnt-avatar-container">
                    <img :src="imgPath + 'avatar.jpg'" alt="" />
                  </div>

                  <div class="cnt-user-name">
                    <h6 class="user-name mb-0">
                      John Doe
                    </h6>
                  </div>
                </mdb-col>
                <mdb-col
                  md="4"
                  class="item-info-container d-flex align-items-center"
                >
                  <ul class="pl-0 m-0">
                    <li class="list-unstyled item-contact">
                      <mdb-icon icon="envelope" class="mr-2"></mdb-icon>
                      mail@mail.com
                    </li>
                    <li class="list-unstyled item-contact">
                      <mdb-icon icon="map-marker-alt" class="mr-2"></mdb-icon>
                      Rue de Rivoli 75004
                    </li>
                  </ul>
                </mdb-col>
                <mdb-col md="2" class="cnt-btn-edite d-flex align-items-center">
                  <router-link :to="{ name: 'editeFt' }"
                    ><mdb-icon icon="pen" class="text-danger"></mdb-icon
                  ></router-link>
                </mdb-col>
              </mdb-row>

              <mdb-row class="pt-4">
                <mdb-col md="12" class="py-4">
                  <mdb-tab tabs color="" class="top-tabs-main">
                    <mdb-tab-item
                      icon="user"
                      :active="pillsActive == 0"
                      @click.native.prevent="pillsActive = 0"
                      >Notification</mdb-tab-item
                    >
                    <mdb-tab-item
                      icon="heart"
                      :active="pillsActive == 1"
                      @click.native.prevent="pillsActive = 1"
                      >Favoris</mdb-tab-item
                    >
                  </mdb-tab>
                  <mdb-tab-content class="tab-container">
                    <mdb-tab-pane class="" key="show1" v-if="pillsActive == 0">
                      <div class="cnt-good-mark w-100">
                        <mdb-row class="pt-4">
                          <mdb-col md="12" class="">
                            <mdb-list-group class="notification-list">
                              <mdb-list-group-item>
                                <mdb-alert color="dark" class="mb-0 w-100">
                                  Grande promotion chez bon pizza
                                </mdb-alert>
                              </mdb-list-group-item>
                              <mdb-list-group-item>
                                Pizza-ka est près de chez vous
                                .....</mdb-list-group-item
                              >
                              <mdb-list-group-item
                                >Pizza-ka est près de chez vous
                                .....</mdb-list-group-item
                              >
                            </mdb-list-group>
                          </mdb-col>
                        </mdb-row>
                      </div>
                    </mdb-tab-pane>
                    <mdb-tab-pane
                      class="fade"
                      key="show2"
                      v-if="pillsActive == 1"
                    >
                      <div class="cnt-good-mark w-100">
                        <mdb-row class="pt-4">
                          <mdb-col md="12" class="">
                            <mdb-list-group class="notification-list">
                              <mdb-list-group-item
                                >Pizza-ka est près de chez vous
                                .....</mdb-list-group-item
                              >
                            </mdb-list-group>
                          </mdb-col>
                        </mdb-row>
                      </div>
                    </mdb-tab-pane>
                  </mdb-tab-content>
                </mdb-col>
              </mdb-row>
            </mdb-col>
            <mdb-col md="4" class="align-items-centerx">
              <mdb-col md="12" class="btn-commande-container text-center py-3">
                <router-link
                  :to="{ name: 'inscft' }"
                  color=""
                  class="danger btn btn-danger btn-commande btn-rounded text-normal btn-theme"
                >
                  Passer en mode restaurateur
                  <mdb-icon icon="exchange-alt" class="ml-3"></mdb-icon>
                </router-link>
              </mdb-col>
            </mdb-col>
          </mdb-row>
        </mdb-col>
      </mdb-container>
    </section>
  </div>
</template>

<script>
import {
  mdbContainer,
  mdbRow,
  mdbCol,
  mdbBreadcrumb,
  mdbBreadcrumbItem,
  mdbIcon,
  mdbTab,
  mdbTabItem,
  mdbTabContent,
  mdbTabPane,
  mdbListGroup,
  mdbListGroupItem,
  mdbAlert,
} from "mdbvue";
export default {
  name: "userAccount",
  components: {
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbBreadcrumb,
    mdbBreadcrumbItem,
    mdbIcon,
    mdbTab,
    mdbTabItem,
    mdbTabContent,
    mdbTabPane,
    mdbListGroup,
    mdbListGroupItem,
    mdbAlert,
  },
  data() {
    return {
      imgPath: "images/",
      pillsActive: 0,
      verticalWithin: 0,
    };
  },
};
</script>

<style scoped></style>
