<template>
  <div style="min-height: 50.2vh;">
    <section class="top-mark-banier pt-4">
      <mdb-container>
        <mdb-col md="12" class="alg-container">
          <div class="cnt-top-crumbs">
            <mdb-breadcrumb class="main-crumbs">
              <mdb-breadcrumb-item
                ><router-link :to="{ name: 'home' }"
                  >Accueil</router-link
                ></mdb-breadcrumb-item
              >
              <mdb-breadcrumb-item
                ><router-link :to="{ name: 'account' }"
                  >compte</router-link
                ></mdb-breadcrumb-item
              >
              <mdb-breadcrumb-item active
                >Informations restaurant</mdb-breadcrumb-item
              >
            </mdb-breadcrumb>
          </div>
          <!-- background: url(' +
                  imgPath +
                  '/bread-delicious-egg-704569.png) no-repeat center; -->
          <mdb-col md="12" class="banner-container p-0">
            <div
              class="top-banner-img w-100 d-flex"
              style="background-color: #dfdfdf;"
            >
              <div class="cnt-mark-logo-detail">
                <span v-if="!isLogo" class="logo-text">
                  Votre <br />
                  logo
                </span>
                <img
                  v-else
                  :src="imgPath + 'logos/bon-pizza.png'"
                  alt=""
                  class="mark-logo"
                />

                <div class="col cnt-btn-edite-avatar cate-edite cate-logo">
                  <mdb-btn icon="pen" class="btn-edite-avatar"></mdb-btn>
                </div>
              </div>

              <div class="col cnt-btn-edite-avatar cate-edite">
                <mdb-btn icon="pen" class="btn-edite-avatar"></mdb-btn>
              </div>
            </div>

            <div class="about-mark-container w-100 pt-5 ">
              <mdb-row class="form-login-container ">
                <mdb-col md="6">
                  <mdb-input
                    label="Texte de présentation"
                    outline
                    type="textarea"
                    rows="3"
                    class="desc"
                  />
                </mdb-col>
                <mdb-col md="6">
                  <mdb-col md="12" class="px-0 inp-item top">
                    <mdb-input
                      label="Lieu de retrait"
                      outline
                      v-model="valueInp"
                    />
                  </mdb-col>
                  <mdb-col md="12" class="px-0 inp-item">
                    <mdb-input
                      label="Jour de retrait"
                      outline
                      v-model="valueInp"
                    />
                  </mdb-col>
                </mdb-col>

                <mdb-col md="12" class="text-center my-3">
                  <mdb-col md="4" class="mx-auto">
                    <mdb-btn
                      type="button"
                      gradient="blue"
                      rounded
                      class="btn-block  btn-theme"
                      >Valider</mdb-btn
                    >
                  </mdb-col>
                </mdb-col>
              </mdb-row>
            </div>
          </mdb-col>
        </mdb-col>
      </mdb-container>
    </section>
  </div>
</template>

<script>
import {
  mdbCol,
  mdbContainer,
  mdbBreadcrumb,
  mdbBreadcrumbItem,
  //   mdbIcon,
  mdbBtn,
  mdbRow,
  //   mdbRating,
  mdbInput,
} from "mdbvue";
export default {
  name: "editeProfileFt",
  components: {
    mdbCol,
    mdbContainer,
    mdbBreadcrumb,
    mdbBreadcrumbItem,
    // mdbIcon,
    mdbBtn,
    mdbRow,
    // mdbRating,
    mdbInput,
  },
  data() {
    return {
      imgPath: "images/",
      productName: "",
      markrating: [
        {
          name: "Pizza margerita 25cm",
          img: "bread-delicious-eggg-704569.png",
          id: "aze54138fsgfh",
          desc:
            "Base creme fraiche, emmental, lardons auxchampignons et fondue de poireaux, oignos, pomme de terre",
          price: "8.00",
        },
        {
          name: "Pizza margerita 25cm",
          img: "bread-delicious-egg-704569.png",
          id: "aze54138fsgfh",
          desc:
            "Base creme fraiche, emmental, lardons auxchampignons et fondue de poireaux, oignos, pomme de terre",
          price: "7.50",
        },
      ],
      modal: false,
      modalInfo: false,
      value: 5,
      value2: 4,
      value3: 0,
      isModalVisible: false,
      backgroundImg: false,
      isLogo: false,
      valueInp: "",
    };
  },
  watch: {
    modal: function() {
      let el = document.body;
      if (this.modal === true) {
        el.classList.add("hidden-bar");
      } else {
        el.classList.remove("hidden-bar");
      }
    },
    modalInfo: function() {
      let el = document.body;
      if (this.modalInfo === true) {
        el.classList.add("hidden-bar");
      } else {
        el.classList.remove("hidden-bar");
      }
    },
  },
};
</script>
>
