import axios from 'axios'
const state = () => ({
    default_token: localStorage.getItem('default_access_token') || null,
    user_access_token: localStorage.getItem('user_access_token') || null
})
const getters = {
    defaultLoggedIn: state => state.default_token,
    userLoggenIn: state => state.user_access_token
}
const actions = {
    defaultLogin: (context) => {
        return new Promise((resolve, reject) => {
            axios.post('/login', {
                email: 'user@maztou.com',
                password: 'maztou123'
            }).then(response => {
                if (response.data) {
                    const data = response.data.data
                    const default_token = data.token
                    localStorage.setItem('default_access_token', default_token)
                    context.commit('defaultLogin', default_token)
                    resolve(default_token)
                }
            }).catch(err => {
                reject(err)
            })
        })
    },

    userLogin: (context, credentials) => {
        return new Promise((resolve, reject) => {
            axios.post('/login', {
                email: credentials.user.email,
                password: credentials.user.password
            }).then(response => {

                if (response.data) {
                    const data = response.data.data
                    localStorage.removeItem('default_access_token')
                    localStorage.setItem('user_access_token', data.token)
                    context.commit('userLogin', data)
                    resolve(data)
                }
            }).catch(err => {
                reject(err)
            })
        })
    }

}

const mutations = {
    defaultLogin: (state, default_token) => {
        return state.default_token = default_token
    },
    userLogin: (state, user_access_token) => {
        return state.user_access_token = user_access_token
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations

}