<template>
  <div>
    <section class="top-mark-banier pt-4" style="background-color: #fff;">
      <mdb-container>
        <mdb-col md="12" class="alg-container">
          <div class="cnt-top-crumbs">
            <mdb-breadcrumb class="main-crumbs">
              <mdb-breadcrumb-item
                ><router-link :to="{ name: 'home' }"
                  >Accueil</router-link
                ></mdb-breadcrumb-item
              >

              <mdb-breadcrumb-item active> Panier</mdb-breadcrumb-item>
            </mdb-breadcrumb>
          </div>

          <mdb-row class="col-cart-container">
            <mdb-col md="6" class="col-cart list-in-cart">
              <mdb-col md="10" class="alg-int-col-cart">
                <mdb-col md="12" class="col-cart-title-container px-0">
                  <h5 class="cart-title">
                    Mon pannier
                  </h5>
                </mdb-col>

                <mdb-col md="12" class="list-in-cart-container px-0">
                  <mdb-col
                    md="12"
                    class="list-item-cart px-0"
                    v-for="(itemCart, it) in itemCarts"
                    :key="it"
                  >
                    <mdb-card>
                      <mdb-card-body>
                        <mdb-card-title
                          tag="h5"
                          class="d-flex aligns-items-center  justify-content-space-between"
                        >
                          {{ itemCart.title }}
                          <div class="cnt-price-item">
                            <span> {{ itemCart.price }} € </span>
                          </div>
                        </mdb-card-title>
                        <mdb-card-text tag="div" class="item-about">
                          <div class="cnt-btn-add-item">
                            <div class="cnt-input-number">
                              <number-control
                                v-model="itemCart.number"
                                :min="0"
                              ></number-control>
                            </div>

                            <div class="cnt-btn-remove">
                              <mdb-btn
                                icon="trash"
                                class="m-0 p-0 btn-25"
                              ></mdb-btn>
                            </div>
                          </div>

                          <div
                            class="cnt-mark-location d-flex aligns-items-center  justify-content-space-between card-title m-0"
                          >
                            <p class="m-0 d-flex">
                              Retrait :
                              <mdb-badge color="" class="ml-2"
                                >26/11/2020</mdb-badge
                              >
                              <mdb-badge color="" class="ml-2">12:00</mdb-badge>
                            </p>
                            <div class="cnt-lnks-title">
                              <mdb-btn
                                @click.native="modalInfo = true"
                                class="mark-info my-0 p-0 btn-25"
                                icon="exclamation-circle r-180"
                              ></mdb-btn>
                            </div>
                          </div>
                        </mdb-card-text>
                      </mdb-card-body>
                    </mdb-card>
                  </mdb-col>
                </mdb-col>
              </mdb-col>
            </mdb-col>
            <mdb-col md="6" class="col-cart cart-total-pay">
              <mdb-col md="8" class="alg-int-col-cart">
                <mdb-col md="12" class="col-cart-title-container px-0">
                  <h5 class="cart-title">
                    Montant à payer
                  </h5>
                </mdb-col>

                <mdb-col md="12" class="px-0 total-price-container">
                  <mdb-list-group class="total-price-item">
                    <mdb-list-group-item
                      >Sous-total<mdb-badge color="" pill>23.50 €</mdb-badge>
                    </mdb-list-group-item>
                    <mdb-list-group-item
                      >Frais de livraison<mdb-badge color="" pill
                        >0.00 €</mdb-badge
                      >
                    </mdb-list-group-item>
                    <mdb-list-group-item class="total-final"
                      >Total<mdb-badge color="" pill>23.50 €</mdb-badge>
                    </mdb-list-group-item>
                  </mdb-list-group>

                  <mdb-col
                    md="12"
                    class="btn-commande-container text-center py-3"
                  >
                    <mdb-btn color="danger" class="btn-commande" rounded>
                      Passer la commande
                    </mdb-btn>
                  </mdb-col>
                </mdb-col>
              </mdb-col>
            </mdb-col>
          </mdb-row>
        </mdb-col>
      </mdb-container>
    </section>
  </div>
</template>

<script>
import {
  mdbContainer,
  mdbRow,
  mdbCol,
  mdbBreadcrumb,
  mdbBreadcrumbItem,
  mdbCard,
  mdbCardBody,
  mdbCardTitle,
  mdbCardText,
  mdbBtn,
  mdbBadge,
  mdbListGroup,
  mdbListGroupItem,
} from "mdbvue";
export default {
  name: "pagePanier",
  components: {
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbBreadcrumb,
    mdbBreadcrumbItem,
    mdbCard,
    mdbCardBody,
    mdbCardTitle,
    mdbCardText,
    mdbBtn,
    mdbBadge,
    mdbListGroup,
    mdbListGroupItem,
  },
  data() {
    return {
      sheepCount: 1,
      itemCarts: [
        {
          title: "Pizza margerita 25cm",
          price: "16,00",
          number: 2,
        },
        {
          title: "Pizza Cambaretti",
          price: "8,00",
          number: 4,
        },
        {
          title: "Pizza Carbonara",
          price: "10,00",
          number: 1,
        },
      ],
    };
  },
};
</script>

<style scoped></style>
