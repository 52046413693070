<template>
  <div style="min-height: 50.2vh;">
    <section class="top-mark-banier pt-4">
      <mdb-container>
        <mdb-col md="12" class="alg-container">
          <div class="cnt-top-crumbs">
            <mdb-breadcrumb class="main-crumbs">
              <mdb-breadcrumb-item
                ><router-link :to="{ name: 'home' }"
                  >Accueil</router-link
                ></mdb-breadcrumb-item
              >
              <mdb-breadcrumb-item active> Vos plats</mdb-breadcrumb-item>
            </mdb-breadcrumb>
          </div>

          <mdb-row class="col-cart-container pt-2">
            <mdb-col md="12" class="px-0 pt-4 pb-2">
              <mdb-col md="12" class="col-cart-title-containe">
                <h5 class="cart-title plats">
                  Vos plats
                  <div class="cnt-notif-btn-plt">
                    <div class="cnt-icon-plt">
                      <mdb-icon icon="utensils"></mdb-icon>
                      <div class="cnt-dishes-counter">
                        {{ markrating.length }}
                      </div>
                    </div>
                    <div class="cnt-btn-add-plt">
                      <mdb-btn class="add-plt btn-theme">
                        Ajouter ici
                      </mdb-btn>
                    </div>
                  </div>
                </h5>
                <p class="sub-title-item m-0">
                  La liste de votre plat est vide
                </p>
              </mdb-col>
            </mdb-col>

            <mdb-col md="12" class="dishes-list-container">
              <div class="cnt-good-mark w-100">
                <mdb-row class="pt-4">
                  <mdb-col
                    md="4"
                    class="item-mark product"
                    v-for="(mark, mr) in markrating"
                    :key="mr"
                  >
                    <mdb-alert color="light" class="d-flex">
                      <div class="cnt-mark-logo">
                        <img :src="imgPath + 'plats/' + mark.img" alt="" />
                      </div>
                      <div class="cnt-about-mark">
                        <div class="top-mark-name">
                          <h6>
                            <router-link
                              :to="{
                                name: '',
                                params: { ID: mark.id, idProduct: mark.name },
                              }"
                            >
                              {{ mark.name }}
                            </router-link>
                          </h6>
                          <p class="desc">
                            {{ mark.desc }}
                          </p>
                        </div>
                        <div class="cnt-mark-location">
                          <p class="m-0 d-flex  align-items-center">
                            {{ mark.price }} €
                          </p>
                          <div class="cnt-lnks-title">
                            <mdb-btn
                              @click.native="modalInfo = true"
                              class="mark-info my-0 p-0"
                              icon="exclamation-circle r-180"
                            ></mdb-btn>
                            <mdb-btn
                              class="mark-heart my-0 p-0 mr-0"
                              icon="plus-circle"
                            ></mdb-btn>
                          </div>
                        </div>
                      </div>
                    </mdb-alert>
                  </mdb-col>
                </mdb-row>
              </div>
            </mdb-col>
          </mdb-row>
        </mdb-col>
      </mdb-container>
    </section>
  </div>
</template>

<script>
import {
  mdbContainer,
  mdbCol,
  mdbBreadcrumb,
  mdbBreadcrumbItem,
  mdbIcon,
  mdbRow,
  mdbBtn,
  mdbAlert,
} from "mdbvue";
export default {
  name: "SingleProduct",
  components: {
    mdbContainer,
    mdbCol,
    mdbBreadcrumb,
    mdbBreadcrumbItem,
    mdbIcon,
    mdbRow,
    mdbBtn,
    mdbAlert,
  },
  data() {
    return {
      imgPath: "images/",
      markrating: [
        {
          name: "Pizza margerita 25cm",
          img: "bread-delicious-eggg-704569.png",
          id: "aze54138fsgfh",
          desc:
            "Base creme fraiche, emmental, lardons auxchampignons et fondue de poireaux, oignos, pomme de terre",
          price: "8.00",
        },
        {
          name: "Pizza margerita 25cm",
          img: "bread-delicious-egg-704569.png",
          id: "aze54138fsgfh",
          desc:
            "Base creme fraiche, emmental, lardons auxchampignons et fondue de poireaux, oignos, pomme de terre",
          price: "7.50",
        },
      ],
    };
  },
};
</script>

<style scoped></style>
