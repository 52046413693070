// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store/store'
import * as VueGoogleMap from 'vuejs-google-maps'
import 'bootstrap-css-only/css/bootstrap.min.css'
import 'mdbvue/lib/css/mdb.min.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import "mdb-calendar/lib/main.css"
import "./components/css/maz.css"

import SideNav from "./components/layouts/SideNav";
import Navbar from "./components/layouts/Navbar";
import HomeNavBar from "./components/layouts/homeNavBar";
import Footer from "./components/layouts/Footer";
import ModalItemInfo from "./components/layouts/modalItemInfo";
import numberControl from "./components/layouts/numberControl";




import VueCarousel from 'vue-carousel';
Vue.use(VueCarousel);

Vue.component('sidenav', SideNav);
Vue.component('navbar', Navbar);
Vue.component('homenavbar', HomeNavBar);
Vue.component('footercopy', Footer);
Vue.component('modalinfo', ModalItemInfo);
Vue.component('number-control', numberControl);
Vue.use(VueGoogleMap, {
    load: {
        apiKey: 'AIzaSyA3Y6CXIRlRKvsKGsdUtZQPKqVGeQdhuVk',
        libraries: ['places']
    }
})

Vue.config.productionTip = false

new Vue({
    render: h => h(App),
    router,
    store,
    components: { App }
}).$mount('#app')